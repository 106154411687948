// extracted by mini-css-extract-plugin
export var Android = "Prototyping-module--Android--4ec9a";
export var arrowDiv = "Prototyping-module--arrowDiv--21f41";
export var arrowImg = "Prototyping-module--arrowImg--95078";
export var boxContent = "Prototyping-module--boxContent--7295c";
export var cards = "Prototyping-module--cards--d1bd3";
export var cardsContent = "Prototyping-module--cardsContent--c14c6";
export var finImg = "Prototyping-module--finImg--59dae";
export var heading = "Prototyping-module--heading--bbd6e";
export var line = "Prototyping-module--line--f4bd3";
export var ser = "Prototyping-module--ser--42975";
export var soft = "Prototyping-module--soft--d5b57";
export var tabImg = "Prototyping-module--tabImg--90abf";