import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/ui-ux-services/Banner"
import Projects from "../components/ui-ux-services/Projects"
import Glance from "../components/ui-ux-services/Glance"
import Prototyping from "../components/ui-ux-services/Prototyping"
import PrototypingMobile from "../components/ui-ux-services/PrototypingMobile"
import Models from "../components/ui-ux-services/Models"
import Revamp from "../components/ui-ux-services/Revamp"
import Process from "../components/ui-ux-services/Process"
import ProcessMobile from "../components/ui-ux-services/ProcessMobile"
import Tools from "../components/ui-ux-services/Tools"
import ToolsMobile from "../components/ui-ux-services/ToolsMobile"
import Faqs from "../components/common/Faqs2"

export const Head = ({ data }) => {
  //   const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="idle"
      />
      {/* <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/Portfolio_059cb9635f.png"
      /> */}
    </>
  )
}
const uiUx = ({ data }) => {
  console.log(data)
  const heroSection = data?.strapiPage?.sections[0]
  const glance = data?.strapiPage?.sections[1]
  const prototyping = data?.strapiPage?.sections[2]
  const model = data?.strapiPage?.sections[3]
  const revamp = data?.strapiPage?.sections[4]
  const benefits = data?.strapiPage?.sections[5]
  const process = data?.strapiPage?.sections[6]
  const stack = data?.strapiPage?.sections[7]
  const faq = data?.strapiPage?.sections[8]

  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 1280) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 1280)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <MainLayout>
      <Banner strapiData={heroSection} />
      <Projects />
      <Glance strapiData={glance} />
      {!isMobile && !isTablet ? (
        <Prototyping strapiData={prototyping} />
      ) : (
        <PrototypingMobile strapiData={prototyping} />
      )}

      <Models strapiData={model} />
      <Revamp strapiData={revamp} />
      {!isMobile && !isTablet ? (
        <Process strapiData={process} />
      ) : (
        <ProcessMobile strapiData={process} />
      )}
      {!isMobile && !isTablet ? (
        <Tools strapiData={stack} />
      ) : (
        <ToolsMobile strapiData={stack} />
      )}
      <Faqs strapiData={faq} />
      <ContactSales bgColor="#F8F8F8" />
    </MainLayout>
  )
}

export const query = graphql`
  query aiDevelopment {
    strapiPage(slug: { eq: "top-ui-ux-design-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default uiUx
