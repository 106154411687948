import React from "react"
import * as styles from "./Process.module.scss"
import Container from "react-bootstrap/Container"

const cardData = [
  {
    title: "Research",
    number: "01",
  },
  {
    number: "02",
    title: "Wireframing",
  },
  {
    number: "03",
    title: "Prototyping",
  },
  {
    number: "04",
    title: "Visual Design",
  },
  {
    number: "05",
    title: "Interaction Design",
  },
  {
    number: "06",
    title: "Iteration",
  },
  {
    number: "07",
    title: "Discovery",
  },
  {
    number: "08",
    title: "User Persona",
  },
  {
    number: "09",
    title: "User Journey",
  },
  {
    number: "10",
    title: "Architecture",
  },
  {
    number: "11",
    title: "Usability Testing",
  },
  {
    number: "12",
    title: "Improvement",
  },
]

const Process = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.mobileWebCmsFeatures}>
        <Container>
          <p className={styles.ser}>Process</p>
          <h2
            className={styles.heading}
            //   dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          >
            Kickstart Your Project with InvoZone’s Reliable{" "}
            <span>UI/UX Process</span>{" "}
            <img
              className={styles.line}
              decoding="async"
              loading="lazy"
              //   style={{ height: "34px", width: "52px"}}
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_599_efa734c410.png"
              }
            />
          </h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />

          <div className={styles.uiUx}>
            <div className={styles.textLeft}>
              {cardData?.map(
                (e, i) =>
                  i < 6 && (
                    <div>
                      <p className={styles.subHeading}>
                        {e?.title} <span className={styles.number1}>{e?.number}</span>
                      </p>
                    </div>
                  )
              )}
            </div>
            <div>
              <img
                src={
                  "https://invozone-backend.s3.us-east-1.amazonaws.com/UX_UI_Process_496abfa090.svg"
                }
              />
            </div>
            <div className={styles.textRight}>
              {cardData?.map(
                (e, i) =>
                  i > 5 && (
                    <div>
                     <p className={styles.subHeading}>
                        {e?.title} <span className={styles.number2}>{e?.number}</span>
                      </p>
                    </div>
                  )
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Process
