// extracted by mini-css-extract-plugin
export var cards = "Tools-module--cards--8c553";
export var cardsContent = "Tools-module--cardsContent--3c7c6";
export var description = "Tools-module--description--1e316";
export var globalTypeScriptTech = "Tools-module--globalTypeScriptTech--29feb";
export var heading = "Tools-module--heading--46019";
export var iconssCard = "Tools-module--iconssCard--243c8";
export var line = "Tools-module--line--e199c";
export var nav = "Tools-module--nav--c0b75";
export var navItem = "Tools-module--nav-item--051c8";
export var navbarBlock = "Tools-module--navbarBlock--5adeb";
export var tabData = "Tools-module--tabData--bad11";
export var tech = "Tools-module--tech--7d7e6";
export var techIcon = "Tools-module--techIcon--074e0";
export var techImg = "Tools-module--techImg--b9408";
export var technologyIcon = "Tools-module--technologyIcon--62de5";