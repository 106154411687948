import React, { useEffect, useRef } from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  // const leftEyeRef = useRef(null);
  // const rightEyeRef = useRef(null);

  // useEffect(() => {
  //   const eyeball = (event) => {
  //     const updateEyePosition = (eye) => {
  //       const x = eye.getBoundingClientRect().left + eye.clientWidth / 2;
  //       const y = eye.getBoundingClientRect().top + eye.clientHeight / 2;
  //       const radian = Math.atan2(event.pageX - x, event.pageY - y);
  //       const rot = radian * (180 / Math.PI) * -1 + 270;
  //       eye.style.transform = `rotate(${rot}deg)`;
  //     };

  //     if (leftEyeRef.current && rightEyeRef.current) {
  //       updateEyePosition(leftEyeRef.current);
  //       updateEyePosition(rightEyeRef.current);
  //     }
  //   };

  //   document.querySelector("body").addEventListener("mousemove", eyeball);
  //   return () => {
  //     document.querySelector("body").removeEventListener("mousemove", eyeball);
  //   };
  // }, []);

  return (
    <div className={styles.portfolioAppWorkBanner}>
      <Container className={styles.bannerCon}>
        <div className={styles.fintech}>
          <Container>
            <div className={styles.Heading}>
              <h1 className={styles.subTitle}>Top UI/UX Design Services</h1>
              <h2 className={styles.bannerHeading}>
                Designing Persona-Driven{" "}
                <img
                  className={styles.arrow}
                  decoding="async"
                  loading="lazy"
                  src={
                    "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007335_14f713dee7.png"
                  }
                />{" "}
                <span>Experiences</span>
              </h2>
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </div>
          </Container>
        </div>
        <div className={styles.emoji}>
          <img
            src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007359_03e3230faa.png"
            decoding="async"
            loading="lazy"
          />
        </div>
        {/* <div className={styles.emoji}>
          <div className={styles.face}>
            <div className={styles.eyes}>
              <div className={styles.eye} ref={leftEyeRef}></div>
              <div className={styles.eye} ref={rightEyeRef}></div>
            </div>
          </div>
        </div> */}
      </Container>
    </div>
  )
}

export default Banner
