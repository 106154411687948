import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./ToolsMobile.scss"
import * as styles from "./ToolsMobile.module.scss"

const TechStacksMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Tools",
      tech: [
        {
          name: "Sketch",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sketch_4d7de29518.svg",
        },
        {
          name: "InVision Studio",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/invision_b6810ba1c2.svg",
        },
        {
          name: "Axure",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/axure_6cf5166c22.svg",
        },
        {
          name: "Craft",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/craft_4a6b2597e7.svg",
        },
        {
          name: "Proto.io",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/proto_io_376044425c.svg",
        },
        {
          name: "Adobe XD",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_xd_61406762fa.svg",
        },
        {
          name: "Marvel Studios",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Marvel_Studios_53fe22e33c.svg",
        },
        {
          name: "Figma",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/figma_39cd9799e4.svg",
        },
        {
          name: "Adobe Photoshop",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_photoshop_d749e40a2e.svg",
        },
        {
          name: "Adobe Illustrator",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_illustrator_d60a58e026.svg",
        },
      ],
    },
    {
      title: "Technologies",
      tech: [
        {
          name: "HTML",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_21e1aa487d.svg",
        },
        {
          name: "CSS",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_20c99da249.svg",
        },
        {
          name: "JavaScript",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_01a3d6c59d.svg",
        },
        {
          name: "SVG",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/svg_15facdf6e4.svg",
        },
      ],
    },
    {
      title: "Typography",
      tech: [
        {
          name: "Google Fonts.",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/figma_39cd9799e4.svg",
        },
        {
          name: "Adobe Fonts",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Adobe_Fonts_26c9a44bea.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  // const [activeAccordionIndex, setActiveAccordionIndex] = useState(0)
  return (
    <div className="TechTypeScriptMobile">
      <div className={styles.globalIndusTypeScriptTech}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`TypeScriptAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3) && e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2) && e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`TypeScriptcardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default TechStacksMobile
