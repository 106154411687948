import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./Tools.scss"
import * as styles from "./Tools.module.scss"

const data = [
  {
    title: "Tools",
    tech: [
      {
        name: "Sketch",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sketch_4d7de29518.svg",
      },
      {
        name: "InVision Studio",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/invision_b6810ba1c2.svg",
      },
      {
        name: "Axure",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/axure_6cf5166c22.svg",
      },
      {
        name: "Craft",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/craft_4a6b2597e7.svg",
      },
      {
        name: "Proto.io",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/proto_io_376044425c.svg",
      },
      {
        name: "Adobe XD",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_xd_61406762fa.svg",
      },
      {
        name: "Marvel Studios",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Marvel_Studios_53fe22e33c.svg",
      },
      {
        name: "Figma",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/figma_39cd9799e4.svg",
      },
      {
        name: "Adobe Photoshop",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_photoshop_d749e40a2e.svg",
      },
      {
        name: "Adobe Illustrator",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_illustrator_d60a58e026.svg",
      },
    ],
  },
  {
    title: "Technologies",
    tech: [
      {
        name: "HTML",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_21e1aa487d.svg",
      },
      {
        name: "CSS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_20c99da249.svg",
      },
      {
        name: "JavaScript",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_01a3d6c59d.svg",
      },
      {
        name: "SVG",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/svg_15facdf6e4.svg",
      },
    ],
  },
  {
    title: "Typography",
    tech: [
      {
        name: "Google Fonts.",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/figma_39cd9799e4.svg",
      },
      {
        name: "Adobe Fonts",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Adobe_Fonts_26c9a44bea.svg",
      },
    ],
  },
]

const TechStacksTypeScript = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="toolsTab">
      <div className={styles.globalTypeScriptTech}>
        <Container>
          <h2
            className={styles.heading}
            //   dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          >
            UI And UX Designing Tools And Technologies With Our{" "}
            <span>Top Technologies</span>, Your Success Is Guaranteed
            <img
              className={styles.line}
              decoding="async"
              loading="lazy"
              //   style={{ height: "34px", width: "52px"}}
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_599_efa734c410.png"
              }
            />
          </h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`toolsTab`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="toolsBlock">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default TechStacksTypeScript
