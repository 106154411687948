import React from "react"
import * as styles from "./Revamp.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Revamp = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.mobileWebCmsFeatures}>
        <Container>
          <p className={styles.ser}>Revamp</p>
          <h2
            className={styles.heading}
            //   dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          >
            Wanna Ditch the “Meh” Factor? Get Your UI/UX{" "}
            <span>Services Now</span>{" "}
            <img
              className={styles.line}
              decoding="async"
              loading="lazy"
              //   style={{ height: "34px", width: "52px"}}
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_599_efa734c410.png"
              }
            />
          </h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`${styles.cardWrapper} gap-30`}>
            {strapiData?.cards?.map((e, i) => (
              <Col className="gap-30" lg={4} md={6} xs={12} key={i}>
                <div className={styles.card}>
                  <lottie-player
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                  />
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Revamp
