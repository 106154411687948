// extracted by mini-css-extract-plugin
export var card1 = "Glance-module--card1--c259d";
export var card2 = "Glance-module--card2--57766";
export var card3 = "Glance-module--card3--72d2c";
export var card4 = "Glance-module--card4--fcbd4";
export var cardWrapper = "Glance-module--cardWrapper--cb3f6";
export var description = "Glance-module--description--8774e";
export var heading = "Glance-module--heading--faf3c";
export var mobileWebCmsFeatures = "Glance-module--mobileWebCmsFeatures--1eccc";
export var ser = "Glance-module--ser--194a2";
export var trailBg = "Glance-module--trailBg--38a65";