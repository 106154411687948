import React from "react"
import { Container } from "react-bootstrap"
import * as styles from "./Projects.module.scss"

const cardData = [
  {
    number: "300+",
    title: "Projects Completed",
  },
  {
    number: "10+",
    title: "Year of Experience",
  },
  {
    number: "500+",
    title: "Developers & Engineers",
  },
  {
    number: "150+",
    title: "Happy Customers",
  },
]

function Projects() {
  return (
    <div className={styles.projects}>
      <Container>
        <div className={styles.numbers}>
          <div className={styles.projectsComplete}>
            {cardData?.map(
              (e, i) =>
                i < 2 && (
                  <div className={styles.projectsDetails}>
                    <p className={styles.number}>{e?.number}</p>
                    <p className={styles.title}>{e?.title}</p>
                  </div>
                )
            )}
          </div>
          <div className={styles.projectsComplete}>
            {cardData?.map(
              (e, i) =>
                i > 1 && (
                  <div className={styles.projectsDetails}>
                    <p className={styles.number}>{e?.number}</p>
                    <p className={styles.title}>{e?.title}</p>
                  </div>
                )
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Projects
