// extracted by mini-css-extract-plugin
export var benefit = "Models-module--benefit--c0f25";
export var benefitCont = "Models-module--benefitCont--c7064";
export var btn = "Models-module--btn--ff8df";
export var cards = "Models-module--cards--57e63";
export var description = "Models-module--description--93aa3";
export var future = "Models-module--future--59e87";
export var heading = "Models-module--heading--f4450";
export var line = "Models-module--line--38fb8";
export var ser = "Models-module--ser--fc9d3";
export var talent = "Models-module--talent--e6b5f";
export var text = "Models-module--text--97e75";
export var tick = "Models-module--tick--392d6";