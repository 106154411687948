import React from "react"
import * as styles from "./Glance.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const cardsData = [
  {
    // title: "Mobile App UI/UX Design",
    title: (
      <>
        Mobile App  <br>UI/UX Design</br>
      </>
    ),

    dec: "Create fluid interfaces for an intuitive and engaging mobile experience.",
  },
  {
    title: "Web UI/UX Design",
    title: (
      <>
        Website <br>UI/UX Design</br>
      </>
    ),
    dec: "Enhance web traffic with modern, dynamic, and innovative design aesthetics.",
  },
  {
    title: "UI/UX Brand Identity",
    title: (
      <>
        UI/UX <br>Brand Identity</br>
      </>
    ),
    dec: "Boost brand growth with expertly crafted, impactful visual design elements.",
  },
  {
    title: "HCI Design",
    // title: (
    //   <>
    //     Website <br>UI/UX Design</br>
    //   </>
    // ),
    dec: "Optimize productivity with user-friendly, research-driven, and efficient designs.",
  },
]

const Glance = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.mobileWebCmsFeatures}>
        <Container>
          <p className={styles.ser}>Expertise</p>
          <h2
            className={styles.heading}
            // dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          >
            UI/UX <span>Design Services</span> At A Glance{" "}
            <img
              className={styles.arrow}
              decoding="async"
              loading="lazy"
              //   style={{ height: "34px", width: "52px"}}
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_a534523059.png"
              }
            />
          </h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`${styles.cardWrapper} gap-30`}>
            {strapiData?.cards?.map((e, i) => (
              <Col className="gap-30" lg={6} md={6} xs={12} key={i}>
                <div
                  className={
                    i === 0
                      ? styles.card1
                      : i === 1
                      ? styles.card2
                      : i === 2
                      ? styles.card3
                      : i === 3
                      ? styles.card4
                      : ""
                  }
                >
                  <h3>{e?.title}</h3>
                  {/* <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    /> */}
                  <p>{e?.subTitle}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Glance
