// extracted by mini-css-extract-plugin
export var description = "Process-module--description--ab349";
export var heading = "Process-module--heading--6b6e8";
export var line = "Process-module--line--4f92b";
export var mobileWebCmsFeatures = "Process-module--mobileWebCmsFeatures--e4d7e";
export var number1 = "Process-module--number1--ecb81";
export var number2 = "Process-module--number2--64608";
export var ser = "Process-module--ser--960f7";
export var subHeading = "Process-module--subHeading--47327";
export var textLeft = "Process-module--textLeft--b962e";
export var textRight = "Process-module--textRight--2a60b";
export var uiUx = "Process-module--uiUx--48595";