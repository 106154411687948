import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import * as styles from "./Models.module.scss"

function Models({ strapiData }) {
  return (
    <div className={styles.benefit}>
      <Container className="benefitCont">
        <div className={styles.future}>
          <p className={styles.ser}>Hiring Models</p>
          <h2
            className={styles.heading}
            // dangerouslySetInnerHTML={{
            //   __html: strapiData?.title,
            // }}
          >
            Hire UI/UX <span>Designers</span>
            <img
              className={styles.line}
              decoding="async"
              loading="lazy"
              //   style={{ height: "34px", width: "52px"}}
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_601_b3c2a63a87.png"
              }
            />
          </h2>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={styles.cards}>
            {strapiData?.cards &&
              strapiData?.cards?.map((item, index) => (
                <div className={styles.talent} key={index}>
                  <img
                    className={styles.tick}
                    src={
                      "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_fc5c1a421a.png"
                    }
                    alt={item?.title}
                  />
                  <div className={styles.text}>
                    <p>{item?.title}</p>
                  </div>
                </div>
              ))}
          </div>
          <Link to="/contact-us/" className={styles.btn}>
            Let's Talk Benefits!
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
              decoding="async"
              loading="lazy"
              alt="explore icon"
            />
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default Models
