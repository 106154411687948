import React from "react"
import * as styles from "./ProcessMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const cardsData = [
  {
    title: "Ul",
    tech: [
      {
        name: "Research",
      },
      {
        name: "Wireframing",
      },
      {
        name: "Prototyping",
      },
      {
        name: "Visual Design",
      },
      {
        name: "Interaction Design",
      },
      {
        name: "Iteration",
      },
    ],
  },
  {
    title: "UX",
    tech: [
      {
        name: "Discovery",
      },
      {
        name: "User Persona",
      },
      {
        name: "User Journey",
      },
      {
        name: "Architecture",
      },
      {
        name: "Usability Testing",
      },
      {
        name: "Improvement",
      },
    ],
  },
]

const Glance = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.mobileWebCmsFeatures}>
        <Container>
          <h2
            className={styles.heading}
            // dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          >
            UI/UX <span>Design Services</span> At A Glance{" "}
            <img
              className={styles.arrow}
              decoding="async"
              loading="lazy"
              //   style={{ height: "34px", width: "52px"}}
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_a534523059.png"
              }
            />
          </h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`${styles.cardWrapper} gap-30`}>
            {cardsData?.map((e, i) => (
              <Col className="gap-30" lg={6} md={6} xs={12} key={i}>
                <div className={styles.card}>
                  <h3>{e?.title}</h3>
                  {e?.tech && (
                    <ul>
                      {e.tech.map((el, i) => (
                        <li key={i}>{el?.name}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Glance
