import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import "./Prototyping.scss"
import * as styles from "./Prototyping.module.scss"

const Prototyping = ({ strapiData }) => {
  let defaultActiveTab = strapiData?.cards?.[0]?.title || "default"
  return (
    <div className={`uiprototypingTab`}>
      <Container>
        <p className={styles.ser}>Prototyping Design</p>
        <h2
          className={styles.heading}
          //   dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        >
          Sculpting Superior Experiences with <span>Prototyping Design</span>{" "}
          <img
            className={styles.arrow}
            decoding="async"
            loading="lazy"
            //   style={{ height: "34px", width: "52px"}}
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_ac31657b6e.png"
            }
          />
          <img
            className={styles.line}
            decoding="async"
            loading="lazy"
            //   style={{ height: "34px", width: "52px"}}
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_599_efa734c410.png"
            }
          />
        </h2>

        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <div className={styles.Android}>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={defaultActiveTab}
          >
            <Row>
              <Col lg={5}>
                <div className={styles.soft}>
                  <Nav variant="pills" className={`uiPrototyping`}>
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Nav.Item key={i}>
                          <div className={styles.tabImg}>
                            <Nav.Link eventKey={`${e?.title}`}>
                              <img
                                decoding="async"
                                loading="lazy"
                                className="tabArrow"
                                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_3ff03bddbb.png"
                              />
                              {e?.title}
                            </Nav.Link>
                          </div>
                        </Nav.Item>
                      ))}
                  </Nav>
                </div>
              </Col>
              <Col lg={7}>
                <div className="tabs">
                  <Tab.Content className="reactTab">
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Tab.Pane eventKey={`${e?.title}`} key={i}>
                          <div className={styles.boxContent}>
                            <div>
                              <div className="d-flex justify-content-between">
                                <div className={`  ${styles.arrowDiv} `}>
                                  {/* <lottie-player
                                    autoplay
                                    loop
                                    src={e?.image1[0]?.localFile?.publicURL}
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                    }}
                                  /> */}
                                </div>
                              </div>
                              <h3>{e?.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              ></p>
                            </div>
                          </div>
                        </Tab.Pane>
                      ))}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </div>
  )
}
export default Prototyping
