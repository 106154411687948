// extracted by mini-css-extract-plugin
export var cardBg = "ToolsMobile-module--cardBg--f65ee";
export var cards = "ToolsMobile-module--cards--6798b";
export var cardsContent = "ToolsMobile-module--cardsContent--e83bc";
export var description = "ToolsMobile-module--description--5128c";
export var globalIndusTypeScriptTech = "ToolsMobile-module--globalIndusTypeScriptTech--0fba0";
export var heading = "ToolsMobile-module--heading--b9024";
export var iconssCard = "ToolsMobile-module--iconssCard--63d35";
export var nav = "ToolsMobile-module--nav--80a9f";
export var navItem = "ToolsMobile-module--nav-item--f176f";
export var navbarBlock = "ToolsMobile-module--navbarBlock--2ec4a";
export var tabData = "ToolsMobile-module--tabData--f599b";
export var tech = "ToolsMobile-module--tech--02be7";
export var techIcon = "ToolsMobile-module--techIcon--22151";
export var techImg = "ToolsMobile-module--techImg--ca138";
export var technologyIcon = "ToolsMobile-module--technologyIcon--35b84";